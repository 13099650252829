import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, WinkStyles } from '@mc/wink';
import NoData from '@mc/components/NoData';
import log from '@mc/es-logger';
import {
  reloadInitComplete,
  reloadWithParam,
} from '../../../Main/ErrorHandler/RouterReload/index';

import stylesheet from './ErrorScreen.less';

const channelName = 'mc-errors-client';

const ErrorScreen = ({
  errorRetry = 'Retry',
  errorSomethingWrong = "Something's gone wrong",
  errorWorkingOnIt = "We're working on it and hope to have it fixed soon.",
  error = null,
  isLoggable = false,
}) => {
  useEffect(() => {
    if (error) {
      // Reload route issue: Something's gone wrong
      if (!reloadInitComplete()) {
        reloadWithParam('route-reload', 1);
      }
    }

    if (isLoggable && error) {
      const errorMessage = `ErrorScreen Outside Boundary Hit: ${error?.message ||
        JSON.stringify(error)}`;

      log({
        channelName: channelName,
        message: errorMessage,
        data: {
          route: {
            pathname: window.location.pathname,
          },
        },
        classification: 'sensitive',
      });
    }
  }, [error, isLoggable]);

  return (
    <WinkStyles>
      <NoData
        description={errorSomethingWrong}
        imgSrc="https://cdn-images.mailchimp.com/error-pages/art-hero-down.png"
      >
        <p className={stylesheet.p}>{errorWorkingOnIt}</p>
        <Button type="tertiary" onClick={() => window.location.reload()}>
          {errorRetry}
        </Button>
      </NoData>
    </WinkStyles>
  );
};

export default ErrorScreen;

ErrorScreen.propTypes = {
  error: PropTypes.any,
  errorRetry: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.shape({}),
  ]),
  errorSomethingWrong: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.shape({}),
  ]),
  errorWorkingOnIt: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.shape({}),
  ]),
  isLoggable: PropTypes.bool,
};
